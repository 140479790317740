@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Styrene A";
  src: url("./fonts/StyreneALC-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Styrene A";
  src: url("./fonts/StyreneALC-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Romie";
  src: url("./fonts/Romie-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

:root {
  /* Typography  */

  /* TODO: Add XL screen vw based text sizes */

  --base-rem: 16;

  --text-4xl-size: 270;
  --text-4xl-size-rem: calc((var(--text-4xl-size) / var(--base-rem) * 1rem));
  --text-4xl-lineHeight: 64px;
  --text-4xl-letterSpacing: -0.12em;

  /* TODO: Talk about making 3xl larger */
  /* --text-3xl-size: 72;
  --text-3xl-size-rem: calc((var(--text-3xl-size) / var(--base-rem) * 1rem));
  --text-3xl-lineHeight: 80px;
  --text-3xl-letterSpacing: 0; */

  --text-3xl-size: 56;
  --text-3xl-size-rem: calc((var(--text-3xl-size) / var(--base-rem) * 1rem));
  --text-3xl-lineHeight: 64px;
  --text-3xl-letterSpacing: 0;

  --text-2xl-size: 48;
  --text-2xl-size-rem: calc((var(--text-2xl-size) / var(--base-rem) * 1rem));
  --text-2xl-lineHeight: 62px;
  --text-2xl-letterSpacing: 0;

  --text-xl-size: 32;
  --text-xl-size-rem: calc((var(--text-xl-size) / var(--base-rem) * 1rem));
  --text-xl-lineHeight: 42px;
  --text-xl-letterSpacing: 0;

  --text-lg-size: 24;
  --text-lg-size-rem: calc((var(--text-lg-size) / var(--base-rem) * 1rem));
  --text-lg-lineHeight: 32px;
  --text-lg-letterSpacing: 0;

  --text-md-size: 20;
  --text-md-size-rem: calc((var(--text-md-size) / var(--base-rem) * 1rem));
  --text-md-lineHeight: 24px;
  --text-md-letterSpacing: 0.05em;

  --text-base-size: 16;
  --text-base-size-rem: calc((var(--text-base-size) / var(--base-rem) * 1rem));
  --text-base-lineHeight: 22px;
  --text-base-letterSpacing: 0;

  --text-sm-size: 13;
  --text-sm-size-rem: calc((var(--text-sm-size) / var(--base-rem) * 1rem));
  --text-sm-lineHeight: 16px;
  --text-sm-letterSpacing: 0.05em;

  --text-xs-size: 9;
  --text-xs-size-rem: calc((var(--text-xs-size) / var(--base-rem) * 1rem));
  --text-xs-lineHeight: 12px;
  --text-xs-letterSpacing: 0.05em;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  --highlight-color: yellow;

  @apply text-fruno-main;
}

::-moz-selection {
  /* Code for Firefox */

  @apply bg-fruno-main text-fruno-sand;
}

::selection {
  @apply bg-fruno-main text-fruno-sand;
}

.label {
  @apply text-sm uppercase font-medium lg:text-base;
}

.triple-banner,
.wine-slider {
  --swiper-pagination-color: #c26d71;
  --swiper-pagination-bottom: 0px;
  --swiper-pagination-bullet-inactive-color: #c26d71;
  --swiper-pagination-bullet-inactive-opacity: 0.4;
  --swiper-pagination-bullet-horizontal-gap: 6px;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 74px;
}

header.main-header {
  transition: all 10s ease, border 0.1s ease;
}

a.main-button.link-underline::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: -2px;
  transition: 0.3s ease-in-out;
}

a.main-button.link-underline:hover::after {
  width: 0%;
  left: unset;
  right: 0;
}

a.main-button.link-glow::before,
.swiper-button::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 150%;
  left: 50%;
  top: 50%;
  opacity: 0.6;
  transform: translate(-50%, -50%) scale(1);
  transition: 0.3s ease-in-out;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #e69a9b 0%,
    rgba(249, 202, 189, 0) 100%
  );
  z-index: -1;
}

.swiper-button::before {
  opacity: 0;
}

a.main-button.link-glow:hover::before,
.swiper-button:hover::before {
  animation: glow 1s infinite alternate;
}

.swiper-button:hover::before {
  opacity: 0.3;
}

@keyframes glow {
  from {
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    transform: translate(-50%, -50%) scale(1.5);
  }
}
